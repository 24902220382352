import { fr, nlBE } from "date-fns/locale";

export const languages = ["nl-BE", "fr-BE"];
// export const languages = ["nl-BE"];
export const defaultLanguage = "nl-BE";
export const locales = {
  "nl-BE": nlBE,
  "fr-BE": fr,
};

export function getLocale(code: string) {
  switch (code) {
    case "nl-BE":
      return locales[code];
    case "fr-BE":
      return locales[code];
    default:
      return locales["nl-BE"];
  }
}
