import React from "react";

interface LoaderProps {
  loaderText?: string;
}

const Loader: React.FC<LoaderProps> = ({ loaderText }) => {
  return (
    <div className="loader">
      <div className="loader__spinner"></div>
      {loaderText && <p className="loader__text">{loaderText}</p>}
    </div>
  );
};

export default Loader;
